<template>
  <div class="container-fluid g-0">
    <div class="row">
      <div class="col-md-12">
        <h4 class="mt-3">Invoices</h4>
      </div>
      <div class="col-md-12" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Invoices',
  data: () => ({
    state: {
      invoices: {},
    },
  }),
  methods: {},
};
</script>

<style></style>
